import PropTypes from "prop-types"
import React from "react"
import { StaticQuery, graphql } from "gatsby"
// import { Box } from "rebass/styled-components"
import Cards from "../Cards"

const ActusRaw = ({ data }) => {
  data.blockSettings = data.blockSettings || {}
  data.blockSettings.align = "left"
  data.style = "listAltenate"
  data.nbCards = "1"
  data.cardFormat = "horizontal"
  data.cards = data.allMarkdownRemark.edges.map((edge, i) => {
    return {
      title: edge.node.frontmatter.title,
      image: edge.node.frontmatter.img.childImageSharp.fixed.src,
      content: edge.node.frontmatter.date + "\n\n" + edge.node.excerpt,
      link: edge.node.fields.slug,
      linklabel: "Lire la suite",
    }
  })
  return <Cards data={data} />
}

ActusRaw.propTypes = {
  data: PropTypes.any,
}

const Actus = () => (
  <StaticQuery
    query={graphql`
      query ActuQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "BlogTemplate" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                date(formatString: "DD/MM/YYYY")
                img {
                  childImageSharp {
                    fixed(width: 250, quality: 100, height: 250) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <ActusRaw data={data} />}
  />
)

export default Actus
