import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Wrapper from "../Wrapper";
import Grid from "../../UI/Grid";
import Markdown from "../../UI/Markdown";
import { Box, Heading, Flex, Text } from "rebass/styled-components";
import loadable from "@loadable/component";
import Forms from "../../Forms";

const Map = loadable(() => import("./Map"));

function isString(str) {
  if (str && typeof str.valueOf() === "string") {
    return true;
  }
  return false;
}

const FormMap = ({ data }) => {
  const hasData = data.form ? !isString(data.form) : false;
  const fluidContainer = data.blockSettings ? data.blockSettings.containerFluid : false;
  return (
    <Wrapper
      data={data}
      containerProps={{
        alignItems: "stretch",
        display: "flex !important",
        px: fluidContainer ? "0px !important" : ""
      }}
    >
      {hasData && (
        <Flex variant="grid" px={[2, 2, 0]}>
          <Flex
            variant="gridItem"
            alignItems="center"
            width={[1, 1, 1 / 2]}
            px={fluidContainer ? [2, 2, 5] : ""}
          >
            <Flex alignItems="center" pl={fluidContainer ? [0, 0, 5] : ""}>
              <Forms data={data.form.frontmatter} />
            </Flex>
          </Flex>
          <Box
            variant="gridItem"
            width={[1, 1, 1 / 2]}
            height={["600px", "800px", "100%"]}
            pl={[0, 0, 5]}
          >
            <Box sx={{ position: "relative" }} height="100%">
              <Map position={data.position} />
              {data.showInfo && (
                <Box
                  backgroundColor="white"
                  sx={{ position: "absolute", bottom: 6, left: 6 }}
                  p={4}
                >
                  <Heading as="h3">{data.cardTitle}</Heading>
                  <Markdown fontSize={1}>{data.address}</Markdown>
                  <Text fontSize={1}>{data.phone}</Text>
                  <Text fontSize={1} mt={3}>
                    {data.email}
                  </Text>
                </Box>
              )}
            </Box>
          </Box>
        </Flex>
      )}
      {!hasData && (
        <Box>
          <Text>Formulaire + carte Non disponible en previsualisation</Text>
        </Box>
      )}
    </Wrapper>
  );
};

FormMap.propTypes = {
  data: PropTypes.shape({
    address: PropTypes.any,
    blockSettings: PropTypes.shape({
      containerFluid: PropTypes.any
    }),
    cardTitle: PropTypes.any,
    email: PropTypes.any,
    form: PropTypes.shape({
      frontmatter: PropTypes.any
    }),
    phone: PropTypes.any,
    position: PropTypes.any,
    showInfo: PropTypes.any
  })
};
export default FormMap;

export const query = graphql`
  fragment FormMapBlock on MarkdownRemarkFrontmatterBlockscollection {
    form {
      frontmatter {
        title
        formId
        successMessage
        elements {
          imputType
          label
          name
          printLabel
          placeholder
          required
          type
          default
          multiple
          options {
            value
            label
          }
        }
      }
    }
    position
    address
    cardTitle
    phone
    email
    showInfo
  }
`;
